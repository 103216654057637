<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="col-md-12" style="background:white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th colspan="2">
                <button
                  class="btn btn-info"
                  style="float: right"
                  @click="showModal"
                >
                  <i class="fa fa-plus"></i> Add Group
                </button>
              </th>
            </tr>

            <tr>
              <th>SN</th>
              <th>Name</th>
              <th>No. of Active Service</th>
              <th>Image</th>
              <th>Featured</th>
              <th style="width:20%">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loadingData && getGroupData.data.length > 0">
            <tr v-for="(item, index) in getGroupData.data" :key="index">
              <td>{{ ++index }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.service_count }}</td>
              <td>
                <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                  <div
                    class="symbol-label"
                    :style="
                      'background-size: contain;background-image: url(' +
                        getGroupData.url +
                        item.image +
                        ')'
                    "
                  ></div>
                </div>
              </td>
              <td>
                <!-- <span class="switch">
                  <input
                    @change="update(item)"
                    type="checkbox"
                    v-model="item.is_featured"
                    class="switch"
                    :id="'group_' + index"
                  />
                  <label :for="'group_' + index"></label>
                </span> -->
                <i class="fa fa-times text-danger" v-if="!item.is_featured"></i>
                <i class="fa fa-check text-success" v-if="item.is_featured"></i>
              </td>

              <td style="width:100px">
                <button
                  class="btn btn-sm btn-primary mr-3 mt-2"
                  style="cursor: pointer;"
                  @click="gotoGroupService(item.id)"
                >
                  Assign service
                </button>

                <button
                  @click="edit(item)"
                  class="btn btn-sm btn-success mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Edit
                </button>
                <button
                  @click="deleteItem(item.id)"
                  class="btn btn-sm btn-danger mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!loadingData && getGroupData.data.length < 1">
            <tr>
              <td colspan="8" class="text-center">No record(s) found</td>
            </tr>
          </tbody>

          <tbody v-if="loadingData">
            <tr>
              <th class="text-center" colspan="10">
                <vDataLoader></vDataLoader>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <group
        v-if="ModalShow"
        v-on:closemodal="closeModal"
        :show-modal="ModalShow"
        :group="groupData"
        :is_edit="is_edit"
      ></group>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_GROUP,
  CREATE_GROUP,
  DELETE_GROUP
} from "@/core/services/store/actions.type";
import group from "@/pages/backend/group/group";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser", "getGroupData", "getBServices"])
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_GROUP);
  },
  components: {
    group
  },
  methods: {
    showModal() {
      this.groupData = {
        id: 0,
        name: "",
        description: "",
        image: "",
        is_featured: false
      };
      this.ModalShow = true;
      this.is_edit = false;
    },
    closeModal() {
      this.ModalShow = false;
      this.$store.dispatch(FETCH_GROUP);
    },
    edit(item) {
      this.ModalShow = true;
      this.is_edit = true;
      this.groupData = {
        ...item,
        image1: this.getGroupData.url + item.image,
        image: "none"
      };
    },
    update(item) {
      let data = { ...item };
      data.image = "none";
      this.$store
        .dispatch(CREATE_GROUP, data)
        .then(() => {
          this.$toastr.s("Group Successfully Updated ");
          this.$store.dispatch(FETCH_GROUP);
        })
        .catch(err => {
          this.errors = err;
        });
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(DELETE_GROUP, id)
            .then(() => {
              this.$toastr.s("Group Deleted ");
              this.$store.dispatch(FETCH_GROUP);
            })
            .catch(err => {
              this.errors = err;
            });
        }
      });
    },
    gotoGroupService(id) {
      this.$router.push({ name: "client.group.service", params: { id: id } });
    }
  },
  data() {
    return {
      loadingData: false,
      ModalShow: false,
      groups: [],
      columns: [
        {
          name: "Group name",
          width: "",
          label: "name ",
          class: ""
        }
      ],
      is_edit: false,
      groupData: {
        id: 0,
        name: "",
        description: "",
        image: "",
        is_featured: false
      },
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Group List",
          route: ""
        }
      ]
    };
  }
};
</script>
