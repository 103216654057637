<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 77%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    background: #1bc5bd;
    color: white;
    border-radius: 3px;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="lg"
    v-model="showModal"
    :title="is_edit ? 'Group Update' : 'Group Create'"
  >
    <div class="row">
      <div class="col-6" style="padding: 10px;">
        <div class="form-group">
          <label for="">Group Name <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="group.name"
            :class="errors['name'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['name']" class="text-danger">{{
            errors["name"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px;">
        <div class="form-group">
          <label for="">Description <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="group.description"
            :class="errors['description'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['description']" class="text-danger">{{
            errors["description"][0]
          }}</span>
        </div>
      </div>

      <div class="form-group col-md-6">
        <div class="journey" v-if="showImage">
          <canvas
            id="imageCanvas"
            ref="imageCanvas"
            style="width: 120px"
          ></canvas>
        </div>
        <div class="journey" v-else>
          <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
            <div
              class="symbol-label"
              :style="
                'background-size: contain;background-image: url(' +
                  group.image1 +
                  ')'
              "
            ></div>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="">Featured </label>
        <span class="switch">
          <input
            type="checkbox"
            v-model="group.is_featured"
            class="switch"
            id="switch-normal"
          />
          <label for="switch-normal"></label>
          <span v-if="errors['is_featured']" class="text-danger">{{
            errors["is_featured"][0]
          }}</span>
        </span>
      </div>
      <div class="form-group col-md-6">
        <label class="custom-file-upload">
          <input
            type="file"
            id="imageLoader"
            @change="updateCanvasImage"
            hidden
          />
          <i class="fa fa-upload mr-2" style="color: white"></i> Upload Image
        </label>
        <span v-if="errors['image']" class="text-danger">{{
          errors["image"][0]
        }}</span>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
        <b-button
          v-if="!is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Save
        </b-button>
        <b-button
          v-if="is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Update
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
import { CREATE_GROUP } from "@/core/services/store/actions.type";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    is_edit: {
      type: Boolean,
      default: false
    },
    group: {
      type: Object,
      default: () => {
        return {
          name: "",
          description: "",
          image: "",
          is_featured: false,
          id: 0
        };
      }
    }
  },
  data() {
    return {
      showImage: false,
      errors: []
    };
  },

  components: {},
  computed: {
    ...mapGetters([])
  },

  methods: {
    close() {
      this.$emit("closemodal");
    },
    save() {
      this.$store
        .dispatch(CREATE_GROUP, this.group)
        .then(() => {
          if (!this.is_edit) {
            this.$toastr.s("Group successfully created");
          } else {
            this.$toastr.s("Group successfully updated");
          }
          this.$emit("closemodal");
        })
        .catch(err => {
          this.errors = err;
        });
    },
    updateCanvasImage(e) {
      this.showImage = true;
      var self = this;

      var reader,
        files = e.target.files;

      reader = new FileReader();

      reader.onload = e => {
        this.e = e;
        var img = new Image();
        img.onload = function() {
          self.drawCanvasImage(img);
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(files[0]);
    },

    drawCanvasImage(imageObj) {
      // const canvas = document.createElement("canvas");

      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;

      // var context = canvas.getContext("2d");
      // // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      // context.drawImage(imageObj, 0, 0);

      // // var canvas = document.getElementById("imageCanvas");
      // var base64 = canvas.toDataURL("image/png");
      // this.image_base64 = base64;

      var canvas = this.$refs.imageCanvas;
      canvas.width = 120;
      canvas.height = (100 * imageObj.height) / imageObj.width;

      var context = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      context.drawImage(
        imageObj,
        0,
        0,
        120,
        (100 * imageObj.height) / imageObj.width
      );

      // var canvas = document.getElementById("imageCanvas");
      var base64 = canvas.toDataURL("image/png");
      this.group.image = base64;
    }
  }
};
</script>
